@import (inline) "~normalize.css";
@import "./base.less";
@import "./animate.less";

// 重置滚动条样式
body{
  -ms-overflow-style: none;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
::-webkit-scrollbar {
  width: 0;
}
.page-header:extend(.clean-padding) {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: @space 90px;
  color: #fff;
  transition: background-color .8s;
  z-index: 10;
  background-color: #FD7621;
  &.fixed {
    background-color: #FD7621;
    color: #222;
    box-shadow: 2px 0 10px rgba(#999, .2);
    .page-menu a {
      // color: #222;
      text-decoration: none;
    }
    // .page-menu li.active a {
    //   color: @theme;
    // }
  }

  .page-header-container {
    height: 44px;
    line-height: 44px;
    font-size: @font-header;
    max-width: 1140px;
    margin: auto;
    overflow: hidden;

    img {
      height: 100%;
    }
  }

  .page-header-simple {
    display: none;
    overflow: hidden;
    color: #fff;
    height: 44px;

    img {
      height: 100%;
    }
  }

  // &.fixed .page-header-simple {
  //   color: #222;
  // }
}

// 展开icon
.header-expand {
  display: inline-block;
  height: 14px;
  width: 23px;
  position: relative;
  margin-top: 16px;

  &:before, &:after {
    content: "";
    right: 0;
    position: absolute;
    display: block;
    width: 100%;
    height: 0;
    border-top: 3px solid;
    bottom: 0;
    transition: 0.3s width .5s;
  }

  &:before {
    top: 0;
    width: 70%;
  }

  &_opened:before {
    width: 100%;
  }

  &_opened:after {
    width: 70%;
  }
}

// 抽屉
.page-drawer {
  list-style: none;
  padding: 0;
  margin: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  color: #fff;
  font-size: 18px;
  z-index: 100;
  text-align: center;
  background-color: rgba(#000, 0);
  overflow: auto;
  transform: translateX(100%);
  transition: transform .5s cubic-bezier(.83,.11,.33,.49);
  li{
    .text{
      a{
        text-decoration: none;
        color: #fff;
      }
    }
    .activeTest{
      a{
        color: #FD7C28;
        font-weight: bold;
      }
    }
  }
  &_opened {
    transform: translateX(0);
    background-color: rgba(#000, .9);
  }

  .header-expand {
    position: absolute;
    right: 26px;
    top: 26px;
    margin: 0;
  }



  .drawer-loop(@i) when (@i >= 0) {
    @nth: (10-@i);
    li:nth-child(@{nth}) {
      line-height: 116px;
      background-color: rgb(17*@i, 17*@i, 17*@i);
    }
    .drawer-loop((@i - 1));
  }

  .drawer-loop(9);
}
.imgMax{
  max-width: 100%;
}
.page-menu {
  list-style: none;
  margin: 0;
  li {
    display: inline-block;
    width: 120px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    border-radius: 23px;
    margin: 0 5px;
    a {
      color: #fff;
      font-size: 22px;
      text-decoration: none;
    }
    &.active a{
      color: #FD7C28;
    }
  }
  li.active{
    background: #FFFFFF;
  }
}

.page-title {
  font-size: 44px;
  font-weight: bold;
  color: #000000;
  font-stretch: normal;
  letter-spacing: -1px;
  margin: 0 0 10px;
  letter-spacing: 5px;
}
.page-subTitle{
  font-size: 26px;
  font-weight: 400;
  color: #000000;
  line-height: 34px;
  margin: 0 0 60px;
  letter-spacing: 3px;
}

.page-container {
  padding: 50px 0;

  .page-container-context {
    text-align: center;
    max-width: 1140px;
    margin: auto;
    .videoAll{
      position: relative;
      margin-top: 65px;
      // background: url('../assets/index/videoIcon.png') no-repeat center;
      // height: 600px;
      height: 100%;
      video {
        opacity: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: .4s opacity;
        cursor: pointer;
      }
        video {
        animation: 1s fade forwards;
      }
      .first-play {
        position: absolute;
        top: 41%;
        left: 45%;
        width: 84px;
        height: 84px;
        cursor: pointer;
        animation: .8s fadeInBottom .2s backwards;
      }
      // .first-play {
      //   animation: .8s fadeOutBottom forwards;
      // }
    }
    .page-first_playing {
      video {
        animation: 1s fade forwards;
      }
      .first-play {
        animation: .8s fadeOutBottom forwards;
      }
    }
  }
  .footerNetwork{
    // max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: wrap;
    color: #939393;
    border-top: 1px solid #777777;
    padding: 20px 0;
    .page-baian-info{
      a{
        color: #939393;
        margin: 0 20px;
        text-decoration: none;
      }
    }
  }
}

// 首屏
.page-first {
  height: 900px;
  // background: #222 url("../assets/film_bg.png") no-repeat center;
  background-color: #FD7621;
  position: relative;
  overflow: hidden;


  .page-first-slogan {
    position: absolute;
    text-align: center;
    width: 1140px;
    height: 355px;
    top: -230px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    color: @theme;
    background-clip: border-box;
    font-size: 50px;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    padding-left: 50px;
    .slogan-left{
      width: 510px;
      margin-top: 40px;
      .left-font{
        letter-spacing:2px;
        font-size: 46px;
        font-family: Swei Spring CJK SC;
        font-weight: 900;
        color: #FFFFFF;
      }
      ul {
        list-style: none;
        margin: 50px 0 0;
        padding: 0;
        overflow: hidden;
        display: inline-block;
        animation: .8s fadeInBottom .1s backwards;

        li {
          width: 500px;
          display: block;
          float: left;
        }

        div.number {
          font-size: 20px;
          line-height: 1;
          letter-spacing: -2px;
          font-weight: 500;
          font-family: Tahoma;
          text-align: left;
          padding: 20px;
          .number-all{
            display: flex;
            align-items: center;
            .idxImg1{
              display: inline;
            }
            .idxImg2{
              display: none;
            }
            img{
              margin-right: 15px;
            }
            .title{
              height: 24px;
              font-size: 20px;
              font-weight: bold;
              color: #FFFFFF;
              letter-spacing: 1px;
            }
          }
          p {
            padding-left: 45px;
            display: none;
            font-size: 14px;
            color: #000000;
          }
        }
      }
      .hoverSty{
        cursor: pointer;
        height:88px;
        background: #FFFFFF;
        border-radius: 10px;
        div.number{
          .number-all{
            display: flex;
            align-items: center;
            .idxImg1{
              display: none;
            }
            .idxImg2{
              display: inline;
            }
            img{
              margin-right: 15px;
            }
            .title{
              color: #FD7621;
              font-weight: 800;
              height: 24px;
              font-size: 20px;
              font-weight: bold;
              letter-spacing: 1px;
            }
          }
          p{
            padding-left: 45px;
            display: inline;
            font-size: 14px;
            color: #000000;
            letter-spacing: 0.7px;
          }
        }
      }
      .qrCode{
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #FFFFFF;
        animation: .9s fadeInBottom .18s backwards;
        margin-top: 65px;
        padding-left: 20px;
        .code{
          width: 240px;
          height: 64px;
          text-align: center;
          line-height: 64px;
          border: 2px solid #FFFCF8;
          border-radius: 10px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          p{
            margin-right: 10px;
          }
          img{
            display: none;
          }
        }
        .appCode{
          .appImg{
            width: 25px;
            height: 29px;
            background: url('../assets/index/home_icon_app_default.png') no-repeat;
          }
        }
        :hover{
          background-color: #ffffff;
          color:#FD7621;
          .appImg{
            background: url('../assets/index/home_icon_app_hover.png') no-repeat;
          }
          img{
            position: absolute;
            top: -105px;
            display: inline;
          }
        }
        .wxCode{
          .wxImg{
            width: 29px;
            height: 28px;
            background: url('../assets/index/home_icon_wechat_default.png') no-repeat;
          }
        }
        :hover{
          background-color: #ffffff;
          color:#FD7621;
          .wxImg{
            background: url('../assets/index/home_icon_wechat_selected.png') no-repeat;
          }
          img{
            position: absolute;
            top: -105px;
            display: inline;
          }
        }
      }
    }
    .phoneImg{
      width: 700px;
      height: 700px;
      margin-left: 50px;
      background: url('../assets/index/home_img_category.png') no-repeat;
      animation: .75s fadeInBottom .1s backwards;
    }
    .phoneOne{
      background: url('../assets/index/home_img_category.png') no-repeat;
    }
    .phoneTwo{
      background: url('../assets/index/home_img_quality.png') no-repeat;
    }
    .phoneThree{
      background: url('../assets/index/home_img_logistics.png') no-repeat;
    }
    .phoneFour{
      background: url('../assets/index/home_img_service.png') no-repeat;
    }
    .first-slogan {
      animation: 1s fadeInBottom backwards;
    }
  }
}

// 服务
.page-grid {
  color: #ED6D00;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
  .gridLine{
    width: 2px;
    height: 96px;
    background: #F7F7F7;
  }
  li {
    max-width: 30%;
    h2{
      font-size: 44px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: -1px;
      margin: 0 0 10px;
    }
    p{
      font-size: 20px;
      font-weight: 400;
      color: #666666;
    }
    // &:nth-child(n+4) {
    //   margin-top: 70px;
    // }
  }

  img {
    margin-bottom: 25px;
  }

  h2 {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: -1px;
    margin: 0 0 10px;
  }

  p {
    color: #777;
    margin: 0;
  }
}
.preceptCol li {
  padding: 25px 10px;
  h2{
    font-size: 20px;
    color: #333333;
  }
  p{
    line-height: 25px;
    font-size: 16px;
  }
}
.preceptH2 li {
  h2{
    color: #000;
    font-size: 22px;
    font-weight: normal;
    letter-spacing: 1px;
  }
}
.preceptSty li{
  width: 300px;
}

.server-grid{
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  .idxBgIcon{
    padding: 0;
    width: 256px;
    height: 256px;
    background: #FFFFFF;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 15px;
    .itemTit{
      margin: 67px 0 20px 0;
    }
    .itemVal{
      text-align: center;
      padding: 0 40px;
      line-height: 22px;
    }
  }
  .backImg1{
    background: url('../assets/index/home_icon__consultant.png') no-repeat center;
  }
  .backImg2{
    background: url('../assets/index/home_icon_quality.png') no-repeat center;
  }
  .backImg3{
    background: url('../assets/index/home_icon_Deliverytostore.png') no-repeat center;
  }
  .backImg4{
    background: url('../assets/index/home_icon_guarantee.png') no-repeat center;
  }
  &-item{
    width: 220px;
    padding: 42px 21px 0 21px;
    height: 340px;
    background: #FFFFFF;
    border-radius: 5px;
    text-align: left;
    box-shadow: #e4e4e4 10px 10px 30px 5px;
    .itemTit{
      color: #000000;
      font-size: 24px;
      font-weight: bold;
      margin: 30px 0 20px 0;
    }
    .itemVal{
      font-size: 16px;
      line-height: 30px;
      color: #666666;
      text-align: left;
    }
  }
}
// 关于我们
.footer-content{
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  flex-flow: row wrap;
  .footer-type{
    color: #FFFFFF;
    text-align: left;
    p{
      font-size: 18px;
    }
    .footer-type-all{
      padding: 0px;
      color: #CFCFCF;
      a{
        text-decoration: none;
        color: #CFCFCF;
      }
      :hover{
        color: #9e9999;
      }
      li{
        height: 40px;
        font-size: 16px;
        list-style: none;
        display: flex;
        align-items: center;
        img{
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
        h3{
          font-size: 16px;
        }
      }
    }
    .fotter-line{
      width: 70px;
      height: 2px;
      background: #ffffff;
      margin: 23px 0;
      .fotter-lineClo{
        height: 2px;
        width: 20px;
        background: #FD7621;
      }
    }
    .footer-type-about{
      padding: 0;
      display: flex;
      width: 336px;
      align-items: center;
      justify-content: space-between;
      li{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        img{
          width: 84px;
          height: 84px;
        }
        h3{
          font-size: 16px;
          color: #A8A8A8;
        }
      }
    }
  }
}
.page-about {
  text-align: left;
  list-style: none;
  margin: 0;
  padding: 0;

  &, & ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  // 圈
  &-tag:extend(.clean-padding) {
    width: 90px;
    height: 90px;
    background-color: #f6f6f6;
    border-radius: 45px;
    text-align: center;
    padding: 32px 0 18px;
    font-family: sans-serif;

    h2, p {
      line-height: 1;
      margin: 0;
    }

    h2 {
      font-size: 26px;
      margin-bottom: 5px;
    }

    p {
      color: #777;
    }
  }

  > li {
    position: relative;
    padding-bottom: 36px;
    height: 150px;
    // 圈下的线条
    &:after {
      content: '';
      position: absolute;
      width: 2px;
      left: 44px;
      top: 120px;
      bottom: -40px;
      margin: auto;
      background-color: #f1f1f1;
      z-index: 0;
    }
  }

  // 第二层
  > li > ul {
    font-size: 18px;
    line-height: 1;
    margin-left: 45px;
    padding-left: 15px;

    > li {
      position: relative;
      margin-top: 36px;
      line-height: 1.5;

      span {
        color: #777;
        display: inline-block;
        width: 176px;
        float: left;
        overflow: hidden;
      }

      &:before {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #ff8e30;
        position: absolute;
        left: -20px;
        top: 8px;
        z-index: 1;
      }
    }
  }

  > li > ul > li {
    // 第三层
    > ul {
      overflow: hidden;

      li {
        margin-bottom: 20px;
        overflow: hidden;

        &:nth-last-child(1) {
          margin-bottom: 0;
        }

        &:before {
          content: '';
          float: left;
          vertical-align: middle;
          width: 5px;
          height: 5px;
          border-radius: 5px;
          margin-right: 18px;
          margin-top: 10px;
          background-color: #a2a2a2;
        }

        div.text {
          overflow: hidden;
        }
      }
    }
  }
}

// 团队成员
.page-member {
  background: #fff;

  &-head {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-flow: row wrap;

    p, b {
      margin: 0;
    }

    b {
      color: #222;
    }

    p {
      color: #777;
    }
  }
}

// 部分合作伙伴，客户案例
.page-cooperate, .page-demo {
  position: relative;
  height: 180px;
}
.page-cooperate>div,
.page-demo>div{
  width: 100%;
  height: 180px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-flow: row wrap;
}
.page-demo>div{
  height: 220px;
}
.page-cooperate .first>div,
.page-cooperate .second>div,
.page-cooperate .three>div{
  width: 180px;
  height: 180px;
}
.page-demo .first>div,
.page-demo .second>div,
.page-demo .three>div{
  width: 220px;
  height: 220px;
}
.page-cooperate .first {
  opacity: 1;
  animation: fadeIn 12s infinite;
}
.page-cooperate .second {
  opacity: 0;
  animation: fadeIn2 12s infinite;
}
.page-cooperate .three {
  opacity: 0;
  animation: fadeIn3 12s infinite;
}
.page-demo .first{
  opacity: 1;
  animation: fadeIn 14s infinite;
}
.page-demo .second{
  opacity: 0;
  animation: fadeIn2 14s infinite;
}
.page-demo .three{
  opacity: 0;
  animation: fadeIn3 14s infinite;
}


.page-cooperate .first div:first-child {
  background-image: url('../assets/cooperate/aj_c.png');
}
.page-cooperate .first div:nth-child(2) {
  background-image: url('../assets/cooperate/hbw_c.png');
}
.page-cooperate .first div:nth-child(3) {
  background-image: url('../assets/cooperate/jdb_c.png');
}
.page-cooperate .first div:nth-child(4) {
  background-image: url('../assets/cooperate/mh_c.png');
}
.page-cooperate .second div:first-child {
  background-image: url('../assets/cooperate/jl_c.png');
}
.page-cooperate .second div:nth-child(2) {
  background-image: url('../assets/cooperate/jinzhong_c.png');
}
.page-cooperate .second div:nth-child(3) {
  background-image: url('../assets/cooperate/new-c.png');
}
.page-cooperate .second div:nth-child(4) {
  background-image: url('../assets/cooperate/sn_c.png');
}
.page-cooperate .three div:first-child {
  background-image: url('../assets/cooperate/sq_c.png');
}
.page-cooperate .three div:nth-child(2) {
  background-image: url('../assets/cooperate/sy_c.png');
}
.page-cooperate .three div:nth-child(3) {
  background-image: url('../assets/cooperate/yzyw_c.png');
}
.page-cooperate .three div:nth-child(4) {
  background-image: url('../assets/cooperate/zl_c.png');
}

.page-demo .first div:first-child {
  background-image: url('../assets/demo/bg_cus.png');
}
.page-demo .first div:nth-child(2) {
  background-image: url('../assets/demo/cxbz_cus.png');
}
.page-demo .first div:nth-child(3) {
  background-image: url('../assets/demo/fssk_cus.png');
}
.page-demo .first div:nth-child(4) {
  background-image: url('../assets/demo/lakf_cus.png');
}
.page-demo .second div:first-child {
  background-image: url('../assets/demo/lmy_cus.png');
}
.page-demo .second div:nth-child(2) {
  background-image: url('../assets/demo/sgkr_cus.png');
}
.page-demo .second div:nth-child(3) {
  background-image: url('../assets/demo/sgmc_cus.png');
}
.page-demo .second div:nth-child(4) {
  background-image: url('../assets/demo/smld_cus.png');
}
.page-demo .three div:first-child {
  background-image: url('../assets/demo/yj_cus.png');
}
.page-demo .three div:nth-child(2) {
  background-image: url('../assets/demo/tjyf_cus.png');
}
.page-demo .three div:nth-child(3) {
  background-image: none;
}
.page-demo .three div:nth-child(4) {
  background-image: none;
}

// 英雄留步
.page-adv-block, .page-join-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
  font-size: 18px;
  list-style: none;
  margin: 0;
  padding: 0;

  .page-adv-item:extend(.clean-padding), .page-join-item:extend(.clean-padding) {
    background-color: #fff;
    border-radius: 4px;
    text-align: left;
    width: 460px;
    margin-top: 20px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(#999, .1);

    &:nth-child(1), &:nth-child(2) {
      margin-top: 0;
    }

    &:nth-last-child(1) {
      padding: 12px 20px;
      overflow: hidden;

      .adv-tag, .join-tag {
        display: inline-block;
        height: 32px;
        line-height: 32px;
        border-radius: 32px;
        background-color: @theme;
        color: #fff;
        padding: 0 @space;
        margin-top: 10px;
      }
      .adv-tag {
        letter-spacing: 1px/2;
      }
    }
    a {
      text-decoration: none;
      color: #222;
    }
    h2 {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
    }

    h2, p {
      margin: 0 0 @space;
      line-height: 1;
    }

    p {
      color: #666;
    }

    p:nth-last-child(1) {
      margin: 0;
    }
  }
}


// 招商加盟
.page-join {
  background: #000;
}

.page-join-block {
  b {
    color: #222;
  }

  .page-join-item {
    height: 360px;

    // 加盟
    &:nth-child(1) {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        // background: #fff url("../assets/join_1.png") no-repeat center;
        background-size: cover;
        width: 100%;
        height: 118px;
        z-index: 0;
      }

      h2 {
        margin-bottom: 45px;
      }
    }

    // 招商
    &:nth-child(2) {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        // background: #fff url("../assets/join_2.png") no-repeat center;
        background-size: cover;
        width: 100%;
        height: 118px;
        z-index: 0;
      }

      h2 {
        margin-bottom: 45px;
      }
    }

    h2 {
      color: #fff;
      font-size: 28px;
      height: 98px;
      line-height: 78px;
      text-align: center;
      z-index: 1;
      position: relative;
    }

    p {
      line-height: 1.4;
    }

    &:nth-last-child(1) h2 {
      text-align: left;
    }
  }
}

// 底部
.page-footer {
  color: #fff;
  background-color: #222;
  padding: 30px 0 0 0;
  .footer-wrapper {
    overflow: hidden;
    text-align: left;
    font-size: 18px;

    .footer-info, .footer-qrcode {
      float: left;
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .footer-info li{
      line-height: 34px;
      margin-bottom: 24px;
      overflow: hidden;

      img {
        float: left;
        vertical-align: middle;
        margin-right: 16px;
      }

      div.text {
        overflow: hidden;
      }
    }

    .footer-qrcode {
      float: right;

      li {
        font-size: 12px;
        color: #222;
        text-align: center;
        background-color: #fff;
        display: inline-block;
        padding: @space;
        border-radius: 4px;
        margin-left: 20px;

        p {
          line-height: 1;
          margin: 10px 0 0;
        }
      }
    }
  }
}

// 招聘信息详情
.recruitment-container {
  padding-top: 20px;
}
.recruitment-top {
  padding-top: 90px;
}
.recruitment-container-context {
  max-width: 1206px;
  text-align: center;
  margin: auto;
}
.recruitment-title {
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  padding: 30px 40px 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    font-size: @font-larger;
    text-align: left;
  }
  p {
    text-align: left;
    font-size: @font-large;
    line-height: 1.5;
    margin: 0;
    color: #666;
  }
  .jobWelfare{
    display: flex;
    flex-flow: wrap;
    width: 550px;
    justify-content: space-between;
    div{
      width: 100px;
      height: 30px;
      background-color: rgba(253, 118, 33, 0.2);
      border-radius: 7px;
      line-height: 30px;
      text-align: center;
      font-size: 18px;
      color: #FD7621;
    }
  }
  .recruitment-phone {
    width: 270px;
    height: 44px;
    background-color: @theme;
    border-radius: 22px;
    line-height: 44px;
    color: #fff;
    letter-spacing: 1px/2;
  }
}
.recruitment-detial{
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
  p {
    font-size: @font-large;
    color: #666;
    margin: 40px 0;
  }
  .recruitment-detials-content{
    padding: 0 40px;
    background-color: #fff;
    text-align: left;
    color: #000;
    max-width: 924px;
    border-radius: 4px;
    ol {
      margin-left: -20px;
    }
    ol li {
      font-size: @font-header;
      line-height: 2;
    }
    .contactUs{
      display: flex;
      list-style: none;
      flex-flow: wrap;
      margin-left: -60px;
      li{
        text-align: center;
        div{
          font-size: 22px;
          color: #453D3F;
        }
      }
    }
  }
  .recruitment-detials-other {
    margin-left: 20px;
    color: #666;
    background-color: #fff;
    padding: 0 30px;
    text-align: left;
    border-radius: 4px;
    .other-position-title {
      span {
        display: inline-block;
        width: 4px;
        height: 19px;
        background-color: @theme;
        margin-right: 10px;
        margin-bottom: -2px;
      }
    }
    .other-position-content {
      margin-bottom: 50px;
      a {
        text-decoration: none;
      }
      h6, p{
        margin: 0;
        line-height: 1.5;
      }
      h6 {
        font-size: @font-header;
        color: #000;
        font-weight: 500;
      }
      p {
        font-size: @font-default;
      }
    }
  }
}

// 备案信息
.page-beian {
  .page-baian-info {
    margin: 0.3em;
  }
}